<template lang="pug">
LoginForm(
  @login="login"
)
</template>

<script>
import LoginForm from "@/components/auth/loginForm";

export default {
  name: "Login",
  components: {
    LoginForm,
  },
  methods: {
    async login(data) {
      try {
        let loginRes = await this.$api.auth.login(data);
        if (loginRes.data.code !== 200) {
          this.$store.dispatch("notification/openNotification", {
            msg: loginRes.data.message,
            color: "red",
            timeout: 5000,
            showClose: false,
          });
        }
        if (loginRes.data.code === 200) {
          this.$router.push("/innerSearch");
          this.$store.dispatch("auth/setAuth", {
            user: data.email,
            token: loginRes.data.content,
            isLogin: true,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async checkToken() {
      if (this.$store.state.auth.token && this.$store.state.auth.token !== "") {
        try {
          let groupRes = await this.$api.common.commonAPI.getClcGroupList();
          if (groupRes.data.code === 200) {
            this.$router.push("/innerSearch");
          }
        } catch (err) {
          console.log(err.code);
        }
      }
    },
  },
  created() {
    this.checkToken();
  },
};
</script>
